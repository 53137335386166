import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'

import type { ColumnSort } from '@tanstack/react-table'
import type { PatientType } from 'types/patients'

import { RootPath } from 'router/enums'
import { defaultColumnOptions } from 'utils/table'
import { getName } from 'utils/text'

export const patientsDefaultSortees: ColumnSort[] = [
  { id: 'name', desc: false },
]

const columnHelper = createColumnHelper<PatientType>()
export const patientsColumns = [
  columnHelper.accessor(
    row =>
      `${row.firstName} ${row.middleName} ${row.lastName} ${row.secondLastName}`,
    {
      ...defaultColumnOptions<PatientType>(),
      header: 'Name',
      id: 'name',
      cell: info => {
        const { id } = info.row.original
        return (
          <Link
            to={`${RootPath.PATIENTS}/${String(id)}`}
            style={{ color: 'var(--colors-primary)' }}
          >
            {getName(info.row.original)}
          </Link>
        )
      },
    },
  ),
  columnHelper.accessor('email', {
    ...defaultColumnOptions<PatientType>(),
    header: 'Email',
    enableSorting: false,
  }),
  columnHelper.accessor('phoneNumber', {
    ...defaultColumnOptions<PatientType>(),
    header: 'Mobile Phone',
    enableSorting: false,
  }),
]
