import { Cross1Icon, PlusIcon } from '@radix-ui/react-icons'
import {
  Button,
  Dialog,
  Flex,
  Text,
  Toggle,
} from '@weareredlight/design-system'
import { useEffect, useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import type { ProcedureType } from 'types/procedures'
import type { PaginatedRequest } from 'types/types'

import { ProceduresFields } from './utils'

import api from 'api/api'
import { ControlledSelectAutocomplete } from 'components/Form'
import { useRequest } from 'hooks/useRequest'

const SplitProcedureForm = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const form = useFormContext()

  const [isDialogOpen, setDialogOpen] = useState(false)
  const [splitProcedure, setSplitProcedure] = useState(false)

  const { data: procedures, doRequest: getProcedures } = useRequest<
    PaginatedRequest<ProcedureType>
  >(api.getProcedures)

  const { fields, remove, append, replace } = useFieldArray({
    control: form.control,
    name: ProceduresFields.SPLIT_PROCEDURES,
  })

  const selectedProcedures = form.watch(ProceduresFields.SPLIT_PROCEDURES)

  const handleToggleChange = (newValue: boolean) => {
    if (!newValue && selectedProcedures?.length > 0) {
      setDialogOpen(true)
    } else {
      setSplitProcedure(newValue)
      if (!newValue) replace([])
    }
  }

  const handleDialogConfirm = () => {
    setSplitProcedure(false)
    replace([])
    setDialogOpen(false)
  }

  const handleDialogCancel = () => {
    setDialogOpen(false)
  }

  useEffect(() => {
    if (selectedProcedures?.length > 0) {
      setSplitProcedure(true)
    }
  }, [selectedProcedures])

  useEffect(() => {
    if (splitProcedure && !procedures?.data) {
      getProcedures()
    }
  }, [splitProcedure, getProcedures, procedures?.data])

  const proceduresOptions = useMemo(() => {
    if (!procedures?.data) return []
    return procedures.data
      .filter(({ id: procedureId }) => procedureId !== id)
      .map(({ id, name }) => ({
        value: id,
        label: name,
      }))
  }, [procedures?.data, id])

  return (
    <Flex direction="column" align="start" gap="xlg" className="form-content">
      <Text variant="h3" color="accent">
        {t('Split Procedure')}
      </Text>

      <Toggle
        id={ProceduresFields.REQUIRES_CELL_BANK}
        label={t('Enable')}
        value={splitProcedure}
        onChange={newValue =>
          handleToggleChange(Boolean(newValue.target.value))
        }
      />

      <Dialog
        open={isDialogOpen}
        onConfirm={handleDialogConfirm}
        closeFn={handleDialogCancel}
        title={t('Are you sure you want to disable split procedure?')}
        description={t(
          'This will remove all selected procedures. This action cannot be undone.',
        )}
        variant="danger"
        confirmButtonText={t('Confirm')}
        cancelButtonText={t('Cancel')}
      />

      {splitProcedure && (
        <Flex
          direction="column"
          align="start"
          gap="xlg"
          className="form-content"
        >
          {fields.map((procedure, index) => (
            <Flex
              key={procedure.id}
              align="start"
              gap="sm"
              css={{ width: '100%' }}
            >
              <Flex
                align="start"
                gap="sm"
                css={{ width: '100%', '@md': { flexDirection: 'column' } }}
              >
                <ControlledSelectAutocomplete
                  name={`${ProceduresFields.SPLIT_PROCEDURES}.${index}`}
                  options={proceduresOptions}
                  placeholder={t('Select procedure')}
                  fullWidth
                />
              </Flex>
              <Flex
                align="start"
                gap="sm"
                css={{ '@md': { flexDirection: 'column' } }}
              >
                <Button
                  variant="textOnly"
                  iconPosition="iconOnly"
                  onClick={() => remove(index)}
                  iconComponent={() => <Cross1Icon />}
                />
              </Flex>
            </Flex>
          ))}
          <Button
            type="button"
            onClick={() => append('')}
            iconComponent={() => <PlusIcon />}
          >
            {t('Add Procedure')}
          </Button>
        </Flex>
      )}
    </Flex>
  )
}

export default SplitProcedureForm
